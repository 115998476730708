<template>
  <div eagle-form-item="multipart-file">
    <multipartUploadFile
      :object-acl="formDataItem.acl"
      :target-uid="targetUid"
      :validExtension="formDataItem.validExtension"
      :validFileType="formDataItem.validFileType"
      :region="formDataItem.region"
      :bucket="formDataItem.bucket"
      :scope="formDataItem.scope"
      :systemFileType="formDataItem.systemFileType"
      :systemFileExtraFileBaseUrl="formDataItem.systemFileExtraFileBaseUrl"
      :disabledCreateFileData="formDataItem.disabledCreateFileData"
      :setFileObjectKey="setFileObjectKey"
      :onUploadCompletedCallback="onUploadCompleted"
    ></multipartUploadFile>
  </div>
</template>

<script>
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data: () => ({
  }),
  methods: {
    setFileObjectKey(extension, fileUid) {
      return this.formDataItem.setFileObjectKey(extension, fileUid)
    },
    onUploadCompleted(data) {
      if(typeof this.formDataItem.onUploadCompleted === 'function') {
        return this.formDataItem.onUploadCompleted(this.formData, data)
      }
      return null
    },
    targetUid() {
      if(typeof this.formDataItem.targetUid === 'function') {
        return this.formDataItem.targetUid(this.formData, this.$route)
      }
      return null
    },
  },
  components: {
    multipartUploadFile: () => import('components/multipartUploadFile/multipartUploadFile.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
